// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';

const MUTATION = gql`
  mutation DossierTypeActionDossiers(
    $actionId: ID!,
    $selectedIds: [ID!],
    $navigationSlug: String!
    $dossierTypeSlug: String!
  ) {
    successful: dossierTypeActionDossiers(
      actionId: $actionId,
      ids: $selectedIds,
      navigationSlug: $navigationSlug,
      dossierTypeSlug: $dossierTypeSlug
    )
  }
`;

export default MUTATION;
